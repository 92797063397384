export function cleanParamsObject(obj) {
    function processValue(value) {
        if (typeof value === 'string') {
            return value.replace(/#.*/g, '');
        } else if (Array.isArray(value)) {
            return value.map(processValue);
        } else if (typeof value === 'object' && value !== null) {
            return cleanParamsObject(value);
        } else {
            return value;
        }
    }

    const newObj = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = processValue(obj[key]);
        }
    }
    
    return newObj;
}