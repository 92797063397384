type Payload = {
    firstName: string;
    lastName: string;
    userId: string;
};

export const nameFormatter = (payload: Payload) => {
    if (!payload) {
        return '';
    }

    const { firstName, lastName, userId } = payload;

    if (firstName === null || lastName === null) {
        return userId;
    }

    return `${firstName} ${lastName}`;
};

export const userBubbleNameFormatter = (payload: Partial<Payload>) => {
    if (!payload) {
        return '';
    }
    const { firstName, lastName, userId } = payload;

    return `${firstName[0]}${lastName[0]}`;
};
