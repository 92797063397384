export const dateFormats = {
    date: 'YYYY-MM-DD',
    time: 'HH:mm',
    dateTime: `YYYY-MM-DD HH:mm`,
    inputDateTime: 'YYYY-MM-DDTHH:mm',
};

export function datediff(first: Date, second: Date): number {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24));
}
