import { CountryCode } from 'tms-client';

export const toReadable = (payload?: CountryCode) => {
    if (!payload) return '';

    switch (payload) {
        case 'AF':
            return 'Afghanistan';
        case 'AL':
            return 'Albania';
        case 'DZ':
            return 'Algeria';
        case 'AS':
            return 'American Samoa';
        case 'AD':
            return 'Andorra';
        case 'AO':
            return 'Angola';
        case 'AI':
            return 'Anguilla';
        case 'AQ':
            return 'Antarctica';
        case 'AG':
            return 'Antigua and Barbuda';
        case 'AR':
            return 'Argentina';
        case 'AM':
            return 'Armenia';
        case 'AW':
            return 'Aruba';
        case 'AU':
            return 'Australia';
        case 'AT':
            return 'Austria';
        case 'AZ':
            return 'Azerbaijan';
        case 'BS':
            return 'Bahamas (the)';
        case 'BH':
            return 'Bahrain';
        case 'BD':
            return 'Bangladesh';
        case 'BB':
            return 'Barbados';
        case 'BY':
            return 'Belarus';
        case 'BE':
            return 'Belgium';
        case 'BZ':
            return 'Belize';
        case 'BJ':
            return 'Benin';
        case 'BM':
            return 'Bermuda';
        case 'BT':
            return 'Bhutan';
        case 'BO':
            return 'Bolivia (Plurinational State of)';
        case 'BQ':
            return 'Bonaire, Sint Eustatius and Saba';
        case 'BA':
            return 'Bosnia and Herzegovina';
        case 'BW':
            return 'Botswana';
        case 'BV':
            return 'Bouvet Island';
        case 'BR':
            return 'Brazil';
        case 'IO':
            return 'British Indian Ocean Territory (the)';
        case 'BN':
            return 'Brunei Darussalam';
        case 'BG':
            return 'Bulgaria';
        case 'BF':
            return 'Burkina Faso';
        case 'BI':
            return 'Burundi';
        case 'CV':
            return 'Cabo Verde';
        case 'KH':
            return 'Cambodia';
        case 'CM':
            return 'Cameroon';
        case 'CA':
            return 'Canada';
        case 'KY':
            return 'Cayman Islands (the)';
        case 'CF':
            return 'Central African Republic (the)';
        case 'TD':
            return 'Chad';
        case 'CL':
            return 'Chile';
        case 'CN':
            return 'China';
        case 'CX':
            return 'Christmas Island';
        case 'CC':
            return 'Cocos (Keeling) Islands (the)';
        case 'CO':
            return 'Colombia';
        case 'KM':
            return 'Comoros (the)';
        case 'CD':
            return 'Congo (the Democratic Republic of the)';
        case 'CG':
            return 'Congo (the)';
        case 'CK':
            return 'Cook Islands (the)';
        case 'CR':
            return 'Costa Rica';
        case 'HR':
            return 'Croatia';
        case 'CU':
            return 'Cuba';
        case 'CW':
            return 'Curaçao';
        case 'CY':
            return 'Cyprus';
        case 'CZ':
            return 'Czechia';
        case 'CI':
            return "Côte d'Ivoire";
        case 'DK':
            return 'Denmark';
        case 'DJ':
            return 'Djibouti';
        case 'DM':
            return 'Dominica';
        case 'DO':
            return 'Dominican Republic (the)';
        case 'EC':
            return 'Ecuador';
        case 'EG':
            return 'Egypt';
        case 'SV':
            return 'El Salvador';
        case 'GQ':
            return 'Equatorial Guinea';
        case 'ER':
            return 'Eritrea';
        case 'EE':
            return 'Estonia';
        case 'SZ':
            return 'Eswatini';
        case 'ET':
            return 'Ethiopia';
        case 'FK':
            return 'Falkland Islands (the) [Malvinas]';
        case 'FO':
            return 'Faroe Islands (the)';
        case 'FJ':
            return 'Fiji';
        case 'FI':
            return 'Finland';
        case 'FR':
            return 'France';
        case 'GF':
            return 'French Guiana';
        case 'PF':
            return 'French Polynesia';
        case 'TF':
            return 'French Southern Territories (the)';
        case 'GA':
            return 'Gabon';
        case 'GM':
            return 'Gambia (the)';
        case 'GE':
            return 'Georgia';
        case 'DE':
            return 'Germany';
        case 'GH':
            return 'Ghana';
        case 'GI':
            return 'Gibraltar';
        case 'GR':
            return 'Greece';
        case 'GL':
            return 'Greenland';
        case 'GD':
            return 'Grenada';
        case 'GP':
            return 'Guadeloupe';
        case 'GU':
            return 'Guam';
        case 'GT':
            return 'Guatemala';
        case 'GG':
            return 'Guernsey';
        case 'GN':
            return 'Guinea';
        case 'GW':
            return 'Guinea-Bissau';
        case 'GY':
            return 'Guyana';
        case 'HT':
            return 'Haiti';
        case 'HM':
            return 'Heard Island and McDonald Islands';
        case 'VA':
            return 'Holy See (the)';
        case 'HN':
            return 'Honduras';
        case 'HK':
            return 'Hong Kong';
        case 'HU':
            return 'Hungary';
        case 'IS':
            return 'Iceland';
        case 'IN':
            return 'India';
        case 'ID':
            return 'Indonesia';
        case 'IR':
            return 'Iran (Islamic Republic of)';
        case 'IQ':
            return 'Iraq';
        case 'IE':
            return 'Ireland';
        case 'IM':
            return 'Isle of Man';
        case 'IL':
            return 'Israel';
        case 'IT':
            return 'Italy';
        case 'JM':
            return 'Jamaica';
        case 'JP':
            return 'Japan';
        case 'JE':
            return 'Jersey';
        case 'JO':
            return 'Jordan';
        case 'KZ':
            return 'Kazakhstan';
        case 'KE':
            return 'Kenya';
        case 'KI':
            return 'Kiribati';
        case 'KP':
            return "Korea (the Democratic People's Republic of)";
        case 'KR':
            return 'Korea (the Republic of)';
        case 'KW':
            return 'Kuwait';
        case 'KG':
            return 'Kyrgyzstan';
        case 'LA':
            return "Lao People's Democratic Republic (the)";
        case 'LV':
            return 'Latvia';
        case 'LB':
            return 'Lebanon';
        case 'LS':
            return 'Lesotho';
        case 'LR':
            return 'Liberia';
        case 'LY':
            return 'Libya';
        case 'LI':
            return 'Liechtenstein';
        case 'LT':
            return 'Lithuania';
        case 'LU':
            return 'Luxembourg';
        case 'MO':
            return 'Macao';
        case 'MG':
            return 'Madagascar';
        case 'MW':
            return 'Malawi';
        case 'MY':
            return 'Malaysia';
        case 'MV':
            return 'Maldives';
        case 'ML':
            return 'Mali';
        case 'MT':
            return 'Malta';
        case 'MH':
            return 'Marshall Islands (the)';
        case 'MQ':
            return 'Martinique';
        case 'MR':
            return 'Mauritania';
        case 'MU':
            return 'Mauritius';
        case 'YT':
            return 'Mayotte';
        case 'MX':
            return 'Mexico';
        case 'FM':
            return 'Micronesia (Federated States of)';
        case 'MD':
            return 'Moldova (the Republic of)';
        case 'MC':
            return 'Monaco';
        case 'MN':
            return 'Mongolia';
        case 'ME':
            return 'Montenegro';
        case 'MS':
            return 'Montserrat';
        case 'MA':
            return 'Morocco';
        case 'MZ':
            return 'Mozambique';
        case 'MM':
            return 'Myanmar';
        case 'NA':
            return 'Namibia';
        case 'NR':
            return 'Nauru';
        case 'NP':
            return 'Nepal';
        case 'NL':
            return 'Netherlands (the)';
        case 'NC':
            return 'New Caledonia';
        case 'NZ':
            return 'New Zealand';
        case 'NI':
            return 'Nicaragua';
        case 'NE':
            return 'Niger (the)';
        case 'NG':
            return 'Nigeria';
        case 'NU':
            return 'Niue';
        case 'NF':
            return 'Norfolk Island';
        case 'MP':
            return 'Northern Mariana Islands (the)';
        case 'NO':
            return 'Norway';
        case 'OM':
            return 'Oman';
        case 'PK':
            return 'Pakistan';
        case 'PW':
            return 'Palau';
        case 'PS':
            return 'Palestine, State of';
        case 'PA':
            return 'Panama';
        case 'PG':
            return 'Papua New Guinea';
        case 'PY':
            return 'Paraguay';
        case 'PE':
            return 'Peru';
        case 'PH':
            return 'Philippines (the)';
        case 'PN':
            return 'Pitcairn';
        case 'PL':
            return 'Poland';
        case 'PT':
            return 'Portugal';
        case 'PR':
            return 'Puerto Rico';
        case 'QA':
            return 'Qatar';
        case 'MK':
            return 'Republic of North Macedonia';
        case 'RO':
            return 'Romania';
        case 'RU':
            return 'Russian Federation (the)';
        case 'RW':
            return 'Rwanda';
        case 'RE':
            return 'Réunion';
        case 'BL':
            return 'Saint Barthélemy';
        case 'SH':
            return 'Saint Helena, Ascension and Tristan da Cunha';
        case 'KN':
            return 'Saint Kitts and Nevis';
        case 'LC':
            return 'Saint Lucia';
        case 'MF':
            return 'Saint Martin (French part)';
        case 'PM':
            return 'Saint Pierre and Miquelon';
        case 'VC':
            return 'Saint Vincent and the Grenadines';
        case 'WS':
            return 'Samoa';
        case 'SM':
            return 'San Marino';
        case 'ST':
            return 'Sao Tome and Principe';
        case 'SA':
            return 'Saudi Arabia';
        case 'SN':
            return 'Senegal';
        case 'RS':
            return 'Serbia';
        case 'SC':
            return 'Seychelles';
        case 'SL':
            return 'Sierra Leone';
        case 'SG':
            return 'Singapore';
        case 'SX':
            return 'Sint Maarten (Dutch part)';
        case 'SK':
            return 'Slovakia';
        case 'SI':
            return 'Slovenia';
        case 'SB':
            return 'Solomon Islands';
        case 'SO':
            return 'Somalia';
        case 'ZA':
            return 'South Africa';
        case 'GS':
            return 'South Georgia and the South Sandwich Islands';
        case 'SS':
            return 'South Sudan';
        case 'ES':
            return 'Spain';
        case 'LK':
            return 'Sri Lanka';
        case 'SD':
            return 'Sudan (the)';
        case 'SR':
            return 'Suriname';
        case 'SJ':
            return 'Svalbard and Jan Mayen';
        case 'SE':
            return 'Sweden';
        case 'CH':
            return 'Switzerland';
        case 'SY':
            return 'Syrian Arab Republic';
        case 'TW':
            return 'Taiwan (Province of China)';
        case 'TJ':
            return 'Tajikistan';
        case 'TZ':
            return 'Tanzania, United Republic of';
        case 'TH':
            return 'Thailand';
        case 'TL':
            return 'Timor-Leste';
        case 'TG':
            return 'Togo';
        case 'TK':
            return 'Tokelau';
        case 'TO':
            return 'Tonga';
        case 'TT':
            return 'Trinidad and Tobago';
        case 'TN':
            return 'Tunisia';
        case 'TR':
            return 'Turkey';
        case 'TM':
            return 'Turkmenistan';
        case 'TC':
            return 'Turks and Caicos Islands (the)';
        case 'TV':
            return 'Tuvalu';
        case 'UG':
            return 'Uganda';
        case 'UA':
            return 'Ukraine';
        case 'AE':
            return 'United Arab Emirates (the)';
        case 'GB':
            return 'United Kingdom of Great Britain and Northern Ireland (the)';
        case 'UM':
            return 'United States Minor Outlying Islands (the)';
        case 'US':
            return 'United States of America (the)';
        case 'UY':
            return 'Uruguay';
        case 'UZ':
            return 'Uzbekistan';
        case 'VU':
            return 'Vanuatu';
        case 'VE':
            return 'Venezuela (Bolivarian Republic of)';
        case 'VN':
            return 'Vietnam';
        case 'VG':
            return 'Virgin Islands (British)';
        case 'VI':
            return 'Virgin Islands (U.S.)';
        case 'WF':
            return 'Wallis and Futuna';
        case 'EH':
            return 'Western Sahara';
        case 'YE':
            return 'Yemen';
        case 'ZM':
            return 'Zambia';
        case 'ZW':
            return 'Zimbabwe';
        case 'AX':
            return 'Åland Islands';
        case 'Unknown':
            return 'Unknown';
        default:
            return 'Unknown';
    }
};
