export const downloadContent = (content: any, name: string) => {
    const a = window.document.createElement('a');

    a.setAttribute('style', 'display:none;');
    window.document.body.appendChild(a);
    a.download = name;
    const url = URL.createObjectURL(content);
    a.href = url;
    a.target = '_blank';
    a.click();
    window.document.body.removeChild(a);

    URL.revokeObjectURL(url);
};
