import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { dateFormats } from './date';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const dateTimeFormatter = (value: string) => {
    if (!value) {
        return;
    }

    return new Date(value).toLocaleString('sv-SE', {
        timeZone: 'Europe/Stockholm',
    });
};

export const getFormattedLocalTime = (dateTimeUtc: Date, format = dateFormats.dateTime): string => {
    return dayjs(dateTimeUtc).utc().local().format(format);
};

export const getFormattedInUtcMode = (dateTime: Date): string => {
    return dayjs(dateTime).utc().format();
};

export const getFormattedTime = (dateTime: Date): string => {
    return dayjs(dateTime).format(dateFormats.dateTime);
};

export const getFormattedDate = (dateTime: Date): string => {
    return dayjs(dateTime).format(dateFormats.date);
};

export const getFormattedDateInputTime = (dateTime: Date): string => {
    return dayjs(dateTime).format(dateFormats.date);
};

export const getRelativeDiff = (dateTime: Date | string): string => {
    return dayjs().to(dateTime);
};
