import { LegalForm } from 'tms-client';

export const toReadable = (payload?: LegalForm) => {
    if (!payload) return '';

    switch (payload) {
        case 'EconomicAssociation':
            return 'Economic Association';
        case 'ForeignCompany':
            return 'Foreign Company';
        case 'Foundation':
            return 'Foundation';
        case 'GeneralPartnership':
            return 'General Partnership';
        case 'GovernmentAuthority':
            return 'Government Authority';
        case 'LimitedCompany':
            return 'Limited Company';
        case 'LimitedPartnership':
            return 'Limited Partnership';
        case 'NonProfitAssociation':
            return 'Non Profit Association';
        case 'SoleProprietorship':
            return 'Sole Proprietorship';
        case 'Subdivision':
            return 'Subdivision';
        case 'Other':
            return 'Other';
        case 'Unknown':
            return 'Unknown';
        default:
            return 'Unknown';
    }
};
