const ROWS_PER_PAGE = 15;
const calculateTotalPages = (totalPages: number, rowsPerPage = ROWS_PER_PAGE) => Math.ceil(totalPages / rowsPerPage);

export const useTotalPagesCount = (total: number) => {
    const count = calculateTotalPages(total);
    const isPaginationEnabled = count > 1;

    return {
        count,
        isPaginationEnabled,
    };
};

export function useSkip(page: number): number {
    if (page === 0) {
        return 0;
    }

    const _page = page === 0 ? 0 : page - 1;
    const skip = _page * ROWS_PER_PAGE;
    return skip;
}
