export class Profile {
    static userId: string;
    static payload: any;

    static load(payload: any) {
        Profile.userId = payload.sub;
        Profile.payload = payload;
    }
}

class User {}
