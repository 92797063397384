export const percentageFormatter = (dividend?: number, divisor?: number): string => {
    const hasOnlyTotal = Number(dividend) && !divisor;
    const hasFalseyParams = [dividend, divisor].some((e) => !Boolean(e));

    if (hasOnlyTotal) {
        return `${Math.round(dividend)}%`;
    }

    if (hasFalseyParams) {
        return '0%';
    }

    const percentage = dividend / divisor;
    const fixed = (percentage * 100).toFixed(1);
    return `${fixed}%`;
};
