import AutoNumeric from 'autonumeric';

export function formatNumber(value: string): string {
    if (value === 'NaN') {
        return value;
    }

    const cleanValue = value.replace(/(?<=\d)\D.*$/g, '');
    const formattedNumber = AutoNumeric.format(cleanValue, {
        digitGroupSeparator: ' ',
        decimalPlaces: 0,
    });

    return formattedNumber;
}
