export class OData {
    static search(expression: string) {
        if (expression.length > 0) {
            return '&$search=' + expression;
        } else {
            return '';
        }
    }

    static filter(predicate: string) {
        if (predicate.length > 0) {
            return '&$filter=' + predicate;
        } else {
            return '';
        }
    }

    static orderby(expression: string) {
        if (expression.trim().length > 0) {
            return '&$orderby=' + expression;
        } else {
            return '';
        }
    }
}
